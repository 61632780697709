import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  CallToActionDecorated,
  Title,
} from '@/styles/global-styled-components';
import { TextSection } from '@/components/Base/TextSection';

const Products = () => {
  const { allProductsJson } = useStaticQuery(graphql`
    query {
      allProductsJson {
        edges {
          node {
            title
            link
            text
            cta
          }
        }
      }
    }
  `);

  return (
    <div
      id="product"
      className="relative page-padding bg-gray-200 py-24 lg:pt-32"
    >
      <Title className="w-full lg:w-2/3">
        Ecosystem of Volatility Products
      </Title>

      <div className=" mb-14 mt-20 grid grid-flow-row auto-rows-min grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        {allProductsJson.edges.map((edge) => (
          <div
            className="bg-white p-12 px-8 w-full last:mr-0 xl:py-20 xl:pl-16 sm:flex lg:block rounded-sm"
            key={edge.node.title}
          >
            {/* <GatsbyImage
              image={edge.node.image.src.childImageSharp.gatsbyImageData}
              alt={edge.node.title}
              className="m-4"
            /> */}
            <div className="max-w-sm">
              <TextSection
                title={edge.node.title}
                text={edge.node.text}
                className="mb-2 sm:mb-8"
              />

              <CallToActionDecorated
                href={edge.node.link}
                target="_blank"
                className="flex items-center"
              >
                <span className="text-sm">{edge.node.cta}</span>
              </CallToActionDecorated>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Products;
