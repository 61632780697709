import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Title } from '@/styles/global-styled-components';
import ListItem from '@/components/Home/Backers/ListItem';

const Partners = () => {
  const { allPartnersJson } = useStaticQuery(graphql`
    query {
      allPartnersJson {
        edges {
          node {
            name
            image {
              src {
                childImageSharp {
                  gatsbyImageData(width: 768)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="page-padding bg-black py-24 lg:py-32">
      <Title light className="w-full lg:w-3/4 xl:w-3/5 2xl:w-2/5 text-white">
        Our Partners
      </Title>
      <p className="mb-4 text-lg text-gray-400 pt-4">
        We partner with the best in the crypto industry.
      </p>

      <div className="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1">
        {allPartnersJson.edges.map((edge) => (
          <div className="max-w-lg" key={edge.node.name}>
            <ListItem edge={edge} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;
