import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Asset,
  LookbackPeriod,
  TimeBucket,
  TimeSeries,
} from '@/types/TimeSeries';
import { useTimeSeriesQuery } from '@/hooks/useTimeSeriesQuery';
import { BeatLoader } from 'react-spinners';
import Chart from '@/components/Home/Timeseries/Chart';
import Variation from '@/components/Home/Timeseries/Variation';
import Controls from '@/components/Home/Timeseries/Controls';
import TodayInfo from '@/components/Home/Timeseries/TodayInfo';
import { getLookbackPeriod } from '@/utils/TimeSeriesUtils';

const ChartSection = () => {
  const [asset, setAsset] = useState<Asset>(Asset.BTC);
  const [timeBucket, setTimeBucket] = useState<TimeBucket>(TimeBucket[`1D`]);
  const [lookbackPeriod, setLookbackPeriod] = useState<LookbackPeriod>(
    getLookbackPeriod(timeBucket),
  );
  const [todayValues, setTodayValues] = useState<Map<Asset, TimeSeries>>(
    new Map(),
  );
  const [yesterdayValues, setYesterdayValues] = useState<
    Map<Asset, TimeSeries>
  >(new Map());

  // grab the data to populate variation and today info separately,
  // since the main query might be set for a time bucket different from 1D when first changing the asset
  const { timeSeries: btcInitialTimeSeries } = useTimeSeriesQuery(
    Asset.BTC,
    TimeBucket[`1D`],
    LookbackPeriod[`1D`],
  );

  const { timeSeries: ethInitialTimeSeries } = useTimeSeriesQuery(
    Asset.ETH,
    TimeBucket[`1D`],
    LookbackPeriod[`1D`],
  );

  useEffect(() => {
    if (btcInitialTimeSeries && btcInitialTimeSeries.length > 1) {
      if (!todayValues.get(Asset.BTC)) {
        setTodayValues(todayValues.set(Asset.BTC, btcInitialTimeSeries.at(-1)));
        setYesterdayValues(
          yesterdayValues.set(Asset.BTC, btcInitialTimeSeries.at(-2)),
        );
      }
    }
    if (ethInitialTimeSeries && ethInitialTimeSeries.length > 1) {
      if (!todayValues.get(Asset.ETH)) {
        setTodayValues(todayValues.set(Asset.ETH, ethInitialTimeSeries.at(-1)));
        setYesterdayValues(
          yesterdayValues.set(Asset.ETH, ethInitialTimeSeries.at(-2)),
        );
      }
    }
  }, [ethInitialTimeSeries, btcInitialTimeSeries]);

  useEffect(() => {
    setLookbackPeriod(getLookbackPeriod(timeBucket));
  }, [timeBucket]);

  const { loading, timeSeries } = useTimeSeriesQuery(
    asset,
    timeBucket,
    lookbackPeriod,
  );

  return (
    <>
      {loading && (
        <BeatLoader className="!flex items-center justify-center h-full" />
      )}
      {!loading && (
        <div className="flex flex-col justify-between relative h-full">
          <TodayInfo
            lowValue={todayValues.get(asset)?.low}
            highValue={todayValues.get(asset)?.high}
          />
          <Chart timeSeries={timeSeries} />

          <div className="flex justify-between items-end">
            <Variation
              currentValue={todayValues.get(asset)?.close}
              previousValue={yesterdayValues.get(asset)?.close}
            />
            <Controls
              asset={asset}
              timeBucket={timeBucket}
              setAsset={setAsset}
              setTimeBucket={setTimeBucket}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChartSection;
