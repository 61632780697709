import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

const BackgroundImage = () => {
  const bgImage = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "main.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, height: 700)
        }
      }
      mobile: file(relativePath: { eq: "main-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <>
      <div className="hidden landscape:block md:absolute -top-6 xl:top-6 2xl:top-20 left-1/2 z-0">
        <GatsbyImage
          image={bgImage.desktop.childImageSharp.gatsbyImageData}
          alt="Tablet"
        />
      </div>

      <div className="hidden portrait:block w-screen">
        <GatsbyImage
          image={bgImage.mobile.childImageSharp.gatsbyImageData}
          alt="Tablet"
        />
      </div>
    </>
  );
};

export default BackgroundImage;
