import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CallToAction, Title } from '@/styles/global-styled-components';
import { TextSection } from '@/components/Base/TextSection';

const UseCases = () => {
  const { allUseCasesJson } = useStaticQuery(graphql`
    query {
      allUseCasesJson {
        edges {
          node {
            title
            link
            text
            image {
              width
              src {
                childImageSharp {
                  gatsbyImageData(width: 160)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="page-padding py-24 lg:pt-32">
      <Title light className="w-full lg:w-2/3 xl:w-3/5 2xl:w-2/5">
        Use cases
      </Title>

      <div className="mt-20 flex flex-wrap">
        {allUseCasesJson.edges.map((edge) => (
          <div
            className="mb-14 sm:mb-24 mr-8 w-full last:mr-0 lg:max-w-sm sm:flex lg:block"
            key={edge.node.title}
          >
            <GatsbyImage
              image={edge.node.image.src.childImageSharp.gatsbyImageData}
              alt={edge.node.title}
              className="m-4"
            />
            <div className="max-w-sm">
              <TextSection
                light
                title={edge.node.title}
                text={edge.node.text}
                className="mb-2 sm:mb-8"
              />

              <CallToAction
                href={edge.node.link}
                target="_blank"
                className="flex items-center"
                light
              >
                <div>↳ Learn more</div>
              </CallToAction>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UseCases;
