import * as React from 'react';
import { FunctionComponent, useState } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import arrowLeftWhite from '../../../assets/images/icons/carousel-arrow-left-white.png';
import arrowLeftGray from '../../../assets/images/icons/carousel-arrow-left-gray.png';
import arrowRightWhite from '../../../assets/images/icons/carousel-arrow-right-white.png';
import arrowRightGray from '../../../assets/images/icons/carousel-arrow-right-gray.png';

interface ArrowProps {
  forward?: boolean;
  onClick?: () => void;
}

const Arrow: FunctionComponent<ArrowProps> = ({ forward, onClick }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const [whiteArrow] = useState(forward ? arrowRightWhite : arrowLeftWhite);
  const [grayArrow] = useState(forward ? arrowRightGray : arrowLeftGray);

  return (
    <div
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      onMouseOver={() => setMouseOver(true)}
      onFocus={() => setMouseOver(true)}
      onMouseOut={() => setMouseOver(false)}
      onBlur={() => setMouseOver(false)}
      className={classNames(`z-1`, { 'absolute right-24': !forward })}
    >
      <img
        src={mouseOver ? whiteArrow : grayArrow}
        alt="arrow"
        className="max-w-fit"
      />
    </div>
  );
};

const sliderSettings = {
  infinite: true,
  speed: 500,
  arrows: true,
  dots: true,
  initialSlide: 1,
  slidesToShow: 1,
  slidesToScroll: 1,
  dotsClass: `slick-dots viv-carousel-dots`,
  className: `viv-carousel !flex mb-8`,
  nextArrow: <Arrow forward />,
  prevArrow: <Arrow />,
};

const Carousel = () => (
  <Slider {...sliderSettings}>
    <div className="pt-6">Use Volmex index-linked products to hedge</div>
    <div className="pt-6">Use Volmex index-linked products to speculate</div>
    <div className="pt-6">Use Volmex indices as an indicator</div>
  </Slider>
);

export default Carousel;
