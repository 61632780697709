import * as React from 'react';
import { FC } from 'react';
import Menu, { MenuItem } from 'rc-menu';
import styled from 'styled-components';
import 'rc-dropdown/assets/index.css';
import Dropdown from 'rc-dropdown';

const DropdownButton = styled.button`
  color: ${(props) => `${props.theme.colors.black}`};
  border: 1px solid ${(props) => `${props.theme.colors.black}`};
  border-radius: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  min-width: 64px;

  @media (max-width: ${(props) => `${props.theme.screens.xl}`}) {
    height: 44px;
    min-width: 44px;
  }
`;

const DropdownMenu = styled(Menu)`
  background: unset;
  border: 0 !important;
  box-shadow: none !important;
`;

const DropdownMenuItem = styled(MenuItem)`
  color: ${(props) => `${props.theme.colors.black}`};
  background-color: ${(props) => `${props.theme.colors.white}`};
  border: 1px solid ${(props) => `${props.theme.colors.black}`};
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  margin-top: 7px !important;
  height: 64px;
  min-width: 64px;

  @media (max-width: ${(props) => `${props.theme.screens.xl}`}) {
    height: 44px;
    min-width: 44px;
  }
`;

const DropdownOptions = ({ options, onSelect }) => {
  if (!options) return null;
  return (
    <DropdownMenu onSelect={(e) => onSelect(e.key)}>
      {options.map((option) => (
        <DropdownMenuItem key={option}>{option}</DropdownMenuItem>
      ))}
    </DropdownMenu>
  );
};

interface SelectProps {
  onSelect: any;
  value: any;
  options: any[];
  className?: string;
  optionClassName?: string;
}

export const Select: FC<SelectProps> = ({
  value,
  options,
  onSelect,
  className,
  optionClassName,
}) => (
  <Dropdown
    trigger={[`click`]}
    placement="topCenter"
    animation="slide-up"
    openClassName="bg-black !text-white"
    overlayClassName={optionClassName || ``}
    overlay={
      <DropdownOptions
        options={options.filter((o) => o !== value)}
        onSelect={onSelect}
      />
    }
  >
    <DropdownButton className={className || ``}>{value}</DropdownButton>
  </Dropdown>
);
