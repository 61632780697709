import * as React from 'react';
import { CallToAction, Title } from '@/styles/global-styled-components';
import { TextSection } from '@/components/Base/TextSection';

const Blurbs = () => {
  const allBlurbs = [
    {
      title: `Permissionless`,
      text: `The Volmex protocol is publicly available and accessible.`,
      link: `https://docs.volmex.finance/`,
    },
    {
      title: `Composability`,
      text: `Volmex leverages layer 2s and scaling solutions to offer users very low fees.`,
      link: `https://docs.volmex.finance/`,
    },
    {
      title: `Low Fees`,
      text: `Volmex leverages layer 2s and scaling solutions to offer users very low fees.`,
      link: `https://docs.volmex.finance/`,
    },
    {
      title: `Transparent`,
      text: `Anyone, anywhere in the world can audit the Volmex smart contracts in real-time.`,
      link: `https://docs.volmex.finance/`,
    },
    {
      title: `24/7 Trading`,
      text: `Trade volatility at any hour of the day or any day of the week.`,
      link: `https://docs.volmex.finance/`,
    },
  ];

  return (
    <div className="page-padding bg-gray-300 pt-24 lg:pt-32 pb-24 sm:pb-0">
      <Title className="w-full lg:w-2/3 xl:w-3/5 2xl:w-2/5">
        Get the benefits of DeFi when trading volatility
      </Title>

      <div className="mt-20 grid gap-12 sm:grid-cols-2 grid-cols-1 lg:grid-cols-3">
        {allBlurbs.map((blurb) => (
          <div className="mb-8 sm:mb-24" key={blurb.title}>
            <TextSection
              title={blurb.title}
              text={blurb.text}
              className="mb-2 sm:mb-8"
            />

            <CallToAction
              href={blurb.link}
              target="_blank"
              className="flex items-center"
            >
              <div>↳ Learn more</div>
            </CallToAction>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blurbs;
