import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

const BackgroundImages = () => (
  <>
    {/* TODO: used a div wrapper for css because the specificity is different at build time compared to runtime between gatsby and tailwind css classes. Investigate why and find a better solution */}
    <div className="absolute md:-right-96 lg:-right-44 2xl:right-0 top-44 lg:top-0 hidden md:block">
      <StaticImage
        src="../../../assets/images/about/tablet-green.png"
        alt="Tablet"
        height={630}
      />
    </div>
    <div className="absolute xl:-left-24 2xl:left-0 bottom-0 hidden xl:block">
      <StaticImage
        src="../../../assets/images/about/tablet-black.png"
        alt="Tablet"
        height={750}
      />
    </div>
    <div className="md:hidden overflow-hidden">
      <StaticImage
        src="../../../assets/images/about/tablet-green-mobile.png"
        alt="Tablet"
      />
    </div>
  </>
);

export default BackgroundImages;
