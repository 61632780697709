import * as React from 'react';
import {
  CallToActionDecorated,
  Title,
} from '@/styles/global-styled-components';
import { TextSection } from '@/components/Base/TextSection';
import BackgroundImages from '@/components/Home/About/BackgroundImages';

const About = () => (
  <div className="bg-gray-100 py-24 lg:py-32 relative overflow-hidden">
    <div className="page-padding flex flex-col items-center">
      <div className="flex flex-col self-start xl:self-auto xl:justify-center lg:w-2/3 xl:w-1/2">
        <Title>What you can do with Volmex.finance</Title>
        <TextSection text="Volmex has a variety of features that make it the best place to start trading" />

        <CallToActionDecorated
          href="https://perpetuals.volmex.finance"
          className="mb-24"
          target="_blank"
        >
          ↳<span>Start trading</span>
        </CallToActionDecorated>

        <TextSection
          title="Crypto Volatility Products"
          text="Get exposure to crypto volatility with one product with no expirations"
        />

        <div className="flex flex-col lg:flex-row justify-between lg:w-max">
          <TextSection
            title="Volmex Indices"
            text="The BVIV Index (Bitcoin Volmex Implied Volatility Index) and EVIV Index (Ethereum Volmex Implied Volatility Index) measure the 30-day implied volatility of Bitcoin and Ethereum respectively."
            className="lg:mr-20"
          />
          <TextSection
            title="More coming soon..."
            text="Keep checking back for product updates"
          />
        </div>
      </div>
    </div>
    <BackgroundImages />
  </div>
);

export default About;
