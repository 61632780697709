import { LookbackPeriod, TimeBucket } from '@/types/TimeSeries';

export const getLookbackPeriod = (timeBucket: TimeBucket) => {
  // this is necessary because for now the API only accepts days as timeBucket
  switch (TimeBucket[timeBucket]) {
    case TimeBucket[`1m`]:
      return LookbackPeriod[`1m`];
    case TimeBucket[`1D`]:
      return LookbackPeriod[`60D`];
    case TimeBucket[`1W`]:
      return LookbackPeriod[`150D`];
    case TimeBucket[`1M`]:
      return LookbackPeriod[`365D`];
    default:
      return LookbackPeriod[`1m`];
  }
};
