import * as React from 'react';
import {
  CallToActionDecorated,
  Title,
} from '@/styles/global-styled-components';
import { TextSection } from '@/components/Base/TextSection';
import TradingViewWidget from './chart';

const Tradingview = () => (
  <div className="overflow-hidden page-padding bg-white flex flex-col lg:flex-row py-24 lg:py-32 lg:justify-between">
    <div className="flex flex-col justify-between lg:w-1/2 lg:pr-12 pb-20 lg:pb-0">
      <Title>
        The world&apos;s first Bitcoin and Ethereum volatility indices available
        on TradingView
      </Title>
      <TextSection
        text={`Search for "Bitcoin Implied" and BVIV is the first result ✨`}
        className="sm:whitespace-nowrap"
      />

      <div className="flex flex-row gap-10">
        <CallToActionDecorated
          href="https://www.tradingview.com/symbols/BVIV/?exchange=VOLMEX"
          target="_blank"
        >
          ↳<span>BVIV</span>
        </CallToActionDecorated>
        <CallToActionDecorated
          href="https://www.tradingview.com/symbols/BVIV/?exchange=VOLMEX"
          target="_blank"
        >
          ↳<span>EVIV</span>
        </CallToActionDecorated>
      </div>
    </div>

    <div className="lg:w-1/2 2xl:w-2/3 lg:m-auto">
      <TradingViewWidget />
    </div>
  </div>
);

export default Tradingview;
