import * as React from 'react';
import styled from 'styled-components';
import { Button } from '@/components/Base/Button';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Newsletter from '../Newsletter';

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  z-index: 1;

  > :first-child {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Buttons = () => (
  <>
    <ButtonsContainer>
      <AnchorLink to="#product">
        <Button color="green">View Products</Button>
      </AnchorLink>
      <a href="https://docs.volmex.finance/" target="_blank">
        <Button color="gray">Learn more</Button>
      </a>
    </ButtonsContainer>
    <div className="max-w-md mt-2">
      <Newsletter />
    </div>
  </>
);

export default Buttons;
