import React from 'react';
import styled from 'styled-components';

const Value = styled.div`
  letter-spacing: -0.073em;
  font-size: 110px;
  @media (max-width: ${(props) => `${props.theme.screens.xl}`}) {
    font-size: 100px;
  }
  @media (max-width: ${(props) => `${props.theme.screens.sm}`}) {
    font-size: 70px;
  }
  color: ${(props) => `${props.theme.colors.black}`};
`;

const SubValueFontSize = styled.div`
  font-size: 24px;
  color: ${(props) => `${props.theme.colors.gray}`};
`;

const Stats = () => (
  <div className="px-6 md:px-11 xl:px-28 2xl:px-32  py-24 lg:py-32 bg-gray-200 overflow-hidden">
    <div className="gap-10 sm:gap-20 lg:gap-56 xl:gap-80 flex flex-col justify-center lg:flex-row items-center mx-0">
      <div>
        {/* <Title>Total value locked</Title> */}
        <Value className="text-center">800,000+</Value>
        <div className="justify-center flex text-gray-500 text-lg">
          <SubValueFontSize>
            <p className="whitespace-nowrap text-center">
              Perpetual Futures DEX Testnet Transactions
            </p>
          </SubValueFontSize>
        </div>
      </div>
      <div>
        {/* <Title>Total value locked</Title> */}
        <Value className="text-center">27,000+</Value>
        <div className="justify-center flex text-gray-500 text-lg">
          <SubValueFontSize>
            <p className="whitespace-nowrap text-center">
              Perpetual Futures DEX Testnet Users
            </p>
          </SubValueFontSize>
        </div>
      </div>
    </div>
  </div>
);

export default Stats;
