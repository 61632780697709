import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// import Slider from 'react-slick';
import { Title } from '@/styles/global-styled-components';
import ListItem from '@/components/Home/Backers/ListItem';

// const sliderSettings = {
//   infinite: true,
//   speed: 4000,
//   autoplay: true,
//   variableWidth: true,
//   arrows: false,
// };

const Backers = () => {
  const { allBackersJson } = useStaticQuery(graphql`
    query {
      allBackersJson {
        edges {
          node {
            name
            image {
              src {
                childImageSharp {
                  gatsbyImageData(width: 736)
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="page-padding bg-gray-100 py-24 lg:py-32">
      <Title className="w-full lg:w-3/4 xl:w-3/5 2xl:w-2/5">
        Backed by leading crypto investors
      </Title>

      {/* <Slider {...sliderSettings} className="!hidden xl:!block mt-10">
        {allBackersJson.edges.map((edge) => (
          <div className="mb-8 mr-4" key={edge.node.name}>
            <ListItem edge={edge} />
          </div>
        ))}
      </Slider> */}

      <div className="mt-12 grid sm:grid-cols-3 grid-cols-1">
        {allBackersJson.edges.map((edge) => (
          <div className="mb-8 max-w-xs" key={edge.node.name}>
            <ListItem edge={edge} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Backers;
