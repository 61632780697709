import * as React from 'react';
import { FC } from 'react';
import { Asset, TimeBucket, TimeBucketLabels } from '@/types/TimeSeries';
import { Select } from '@/components/Base/Select';

interface ControlProps {
  asset: Asset;
  timeBucket: TimeBucket;
  setAsset: any;
  setTimeBucket: any;
}

const Controls: FC<ControlProps> = ({
  asset,
  setAsset,
  timeBucket,
  setTimeBucket,
}) => (
  <div className="flex pr-2.5">
    <Select
      onSelect={setTimeBucket}
      value={timeBucket}
      options={TimeBucketLabels}
      className="mr-2"
    />
    <Select onSelect={setAsset} value={asset} options={Object.values(Asset)} />
  </div>
);

export default Controls;
