export const formatNumber = (value: number) => {
  if (!value) {
    return null;
  }

  return new Intl.NumberFormat(`en-US`, {
    maximumFractionDigits: 2,
  }).format(value);
};

export const formatDate = (date: Date) => {
  if (!date || date.toString() === `Invalid Date`) {
    return null;
  }

  return new Intl.DateTimeFormat(`en-GB`, {
    day: `2-digit`,
    month: `short`,
    year: `numeric`,
    hour: `2-digit`,
    minute: `2-digit`,
    second: `2-digit`,
    hour12: false,
  })
    .format(date)
    .replace(`,`, ``);
};
