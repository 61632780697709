import * as React from 'react';
import { formatNumber } from '@/utils/FormattingUtils';

const TodayInfo = ({ lowValue, highValue }) => {
  if (!lowValue || !highValue) {
    return null;
  }

  return (
    <div className="flex flex-col text-sm lg:text-base text-gray-400 absolute -top-14 left-2">
      <div className="text-black">Today</div>
      <div>Low of {formatNumber(lowValue)}</div>
      <div>High of {formatNumber(highValue)}</div>
    </div>
  );
};

export default TodayInfo;
