import { REST_API_URL } from '@/constants';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export const useBitfinexTotalNotional = () => {
  const { data: totalNotional } = useQuery(
    [`bitfinex-total-notional`],
    async () => {
      const res = await axios.get<{
        data: number;
      }>(`${REST_API_URL}/public/bitfinex-total-notional`);
      return res.data.data;
    },
    {
      refetchInterval: 60 * 5 * 1000,
    },
  );

  return totalNotional;
};
