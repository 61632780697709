import * as React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Subtitle } from '@/styles/global-styled-components';

const BasicText = styled.div`
  color: ${(props) => props.theme.colors.gray[400]};
  padding-top: 1em;
  max-width: 410px;
`;

interface SectionProps {
  title?: string;
  text: string;
  light?: boolean;
  className?: string;
}

export const TextSection: FunctionComponent<SectionProps> = ({
  title,
  text,
  light,
  className,
}) => (
  <div className={`mb-12 ${className || ``}`}>
    <Subtitle light={light}>{title}</Subtitle>
    <BasicText>{text}</BasicText>
  </div>
);
