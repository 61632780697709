import * as React from 'react';
import {
  CallToActionDecorated,
  Title,
} from '@/styles/global-styled-components';
import { TextSection } from '@/components/Base/TextSection';
import ChartSection from '@/components/Home/Timeseries/ChartSection';

const Timeseries = () => (
  <div className="page-padding bg-white flex flex-col lg:flex-row py-24 lg:py-32">
    <div className="flex flex-col justify-between lg:w-1/2 2xl:w-1/3 lg:pr-12 pb-20 lg:pb-0">
      <Title>The world’s first crypto volatility indices</Title>
      <TextSection text="BVIV (Bitcoin Volmex Implied Volatility) and EVIV (Ethereum Volmex Implied Volatility) Indices" />

      <CallToActionDecorated
        href="https://charts.volmex.finance/"
        target="_blank"
      >
        ↳<span>Track it</span>
      </CallToActionDecorated>
    </div>

    <div className="lg:w-1/2 2xl:w-2/3 ">
      <ChartSection />
    </div>
  </div>
);

export default Timeseries;
