import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
// import { Subtitle } from '@/styles/global-styled-components';

const ListItem = ({ edge }) => (
  <>
    <GatsbyImage
      image={edge.node.image.src.childImageSharp.gatsbyImageData}
      alt={edge.node.name}
      // style={{ border: `1px solid rgba(91, 99, 110, 0.2)` }}
      className="mb-3 grayscale hover:grayscale-0"
    />
    {/* <Subtitle shrink>{edge.node.name}</Subtitle> */}
  </>
);

export default ListItem;
