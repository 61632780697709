import * as React from 'react';
import styled from 'styled-components';
import { Heading, Subtitle } from '@/styles/global-styled-components';
import VivIndex from '@/components/Home/Main/VivIndex';
import Buttons from '@/components/Home/Main/Buttons';
import BackgroundImage from '@/components/Home/Main/BackgroundImage';
// import Carousel from '@/components/Home/Main/Carousel';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import PerpetualsTraded from './PerpetualsTraded';

const ViewportHeight = styled.div`
  @media ${(props) => `${props.theme.screens.landscape.raw}`} {
    min-height: 700px;
    height: 93vh;
    max-height: -webkit-fill-available; // for safari
  }
`;

const Main = () => {
  const { width } = useWindowDimensions();
  return (
    <ViewportHeight className="page-padding bg-black flex flex-col justify-between relative overflow-hidden">
      <div>
        <div className="landscape:w-1/2">
          <Heading className="pt-32 pb-6">
            Leading Crypto Volatility Indices and Volatility Products
          </Heading>
          <Subtitle light className="leading-7">
            The home of crypto volatility
          </Subtitle>
        </div>
        <Buttons />
      </div>

      <BackgroundImage />

      <div className="flex justify-between items-end">
        <VivIndex />
        {/* if you try to use css to do this, the text of the carousel disappears, when going from mobile to desktop width screen size */}
        {width > 1024 && <PerpetualsTraded />}
      </div>
    </ViewportHeight>
  );
};

export default Main;
