import * as React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { formatNumber } from '@/utils/FormattingUtils';

const Variation = ({ previousValue, currentValue }) => {
  const [variation, setVariation] = useState<string>();
  const [hasIncreased, setHasIncreased] = useState(false);

  useEffect(() => {
    if (previousValue && currentValue) {
      const difference = currentValue - previousValue;
      const percentage = (difference / previousValue) * 100;
      setVariation(formatNumber(percentage));
      setHasIncreased(currentValue > previousValue);
    } else {
      setVariation(null);
    }
  }, [currentValue, previousValue]);

  return (
    <div className="relative pl-2.5">
      {variation && (
        <div className="text-black text-4xl xl:text-7xl flex items-center relative">
          <div
            className={classNames(
              `h-11 w-11 xl:h-16 xl:w-16 text-2xl xl:text-4xl flex items-center justify-center mr-2`,
              {
                'bg-green-100': hasIncreased,
                'bg-pink': !hasIncreased,
              },
            )}
          >
            {hasIncreased ? <>↑</> : <>↓</>}
          </div>
          {hasIncreased && <>+</>}
          {variation}%
          <div className="absolute text-gray-400 text-base top-0 -right-11">
            24hrs
          </div>
        </div>
      )}
    </div>
  );
};

export default Variation;
