import styled, { css } from 'styled-components';

interface TextProps {
  light?: boolean;
  shrink?: boolean;
}

export const Heading = styled.div`
  font-size: 90px;
  letter-spacing: -0.055em;
  color: ${(props) => `${props.theme.colors.white}`};
  line-height: 1;
  @media (max-width: ${(props) => `${props.theme.screens[`2xl`]}`}) {
    font-size: 70px;
  }
  @media (max-width: ${(props) => `${props.theme.screens.xl}`}) {
    font-size: 60px;
  }
  @media (max-width: ${(props) => `${props.theme.screens.lg}`}) {
    font-size: 40px;
  }
`;

export const Title = styled.div<TextProps>`
  font-size: 58px;
  letter-spacing: -0.03em;
  line-height: 60px;
  word-break: break-word;
  color: ${(props) =>
    props.light
      ? `${props.theme.colors.white};`
      : `${props.theme.colors.gray[900]}`};
  @media (max-width: ${(props) => `${props.theme.screens.md}`}) {
    font-size: 40px;
  }
`;

export const Subtitle = styled.div<TextProps>`
  font-size: 28px;
  color: ${(props) =>
    props.light
      ? `${props.theme.colors.white};`
      : `${props.theme.colors.gray[900]}`};

  ${(props) =>
    props.shrink &&
    css`
      @media (max-width: ${props.theme.screens.md}) {
        font-size: 26px;
      }
    `}
`;

export const CallToActionDecorated = styled.a<TextProps>`
  font-size: 32px;
  width: fit-content;
  color: ${(props) =>
    props.light
      ? `${props.theme.colors.white};`
      : `${props.theme.colors.black}`};
  span {
    border-bottom: 3px solid ${(props) => `${props.theme.colors.green[100]}`};
  }
`;

export const CallToAction = styled.a<TextProps>`
  font-size: 24px;
  width: fit-content;
  color: ${(props) =>
    props.light
      ? `${props.theme.colors.white};`
      : `${props.theme.colors.black}`};
`;
