import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { CallToAction, Title } from '@/styles/global-styled-components';
import { TextSection } from '@/components/Base/TextSection';
import { useState } from 'react';

const formatDate = (time: number) => {
  const date = new Date(time * 1000);
  const options = {
    year: `numeric`,
    month: `2-digit`,
    day: `2-digit`,
  } as const;
  const dateString = date.toLocaleDateString(`en-US`, options);

  const modifiedDateString = dateString.replace(/\//g, `-`);

  return modifiedDateString;
};

const Research = () => {
  const { allResearchJson } = useStaticQuery(graphql`
    query {
      allResearchJson {
        edges {
          node {
            date
            title
            text
            path
          }
        }
      }
    }
  `);

  const [showMore, setShowMore] = useState(false);

  const miminizedNumberOfResearch = 4;

  const filteredResearch = showMore
    ? allResearchJson.edges
    : allResearchJson.edges.slice(0, miminizedNumberOfResearch);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="page-padding py-24 lg:pt-32">
      <div className="absolute z-10 -mt-60" id="research" />
      <Title light className="w-full lg:w-2/3 xl:w-3/5 2xl:w-2/5">
        Latest Research
      </Title>

      <div className="mt-20 flex flex-wrap">
        {filteredResearch.map((edge) => (
          <div
            className="mb-14 sm:mb-24 mr-8 w-full last:mr-0 lg:max-w-sm flex lg:block"
            key={edge.node.title}
          >
            <div className="max-w-sm">
              <p>
                <span className="text-sm text-gray-400">
                  {formatDate(edge.node.date)}
                </span>
              </p>
              <TextSection
                light
                title={edge.node.title}
                text={edge.node.text}
                className="mb-2 sm:mb-8"
              />

              <CallToAction
                href={edge.node.path}
                target="_blank"
                className="flex items-center"
                light
              >
                <div>↳ Read</div>
              </CallToAction>
            </div>
          </div>
        ))}
      </div>

      {/* See More Section */}
      <div className="-mt-4 text-center">
        {!showMore && (
          <button
            type="button"
            onClick={toggleShowMore}
            className="inline-block underline text-lg"
          >
            {showMore ? `Hide` : `See More`}
          </button>
        )}
      </div>
    </div>
  );
};

export default Research;
