import * as React from 'react';
import {
  CallToActionDecorated,
  Title,
} from '@/styles/global-styled-components';
import { StaticImage } from 'gatsby-plugin-image';

const Bitfinex = () => (
  <div className="bg-black overflow-hidden page-padding flex flex-col py-24 lg:py-32 lg:justify-between gap-20">
    <div className="justify-center w-full flex">
      <div className="flex gap-6 justify-end">
        <p
          className="text-5xl font-medium -mt-[2.5px]"
          style={{
            fontSize: `54px`,
          }}
        >
          Available on
        </p>
        {` `}
        <div>
          <StaticImage
            src="../../../assets/images/bitfinex-logo.png"
            alt="Logo"
            loading="eager"
            className="lg:mr-32"
            height={60}
          />
        </div>
      </div>
    </div>
    <div className="flex flex-col justify-between pb-20 lg:pb-0">
      <Title light>
        Trade BVIV and EVIV perpetual contracts on Bitfinex, one of the first
        and most liquid crypto exchanges
      </Title>

      <div className="flex flex-row gap-10 text-white pt-20">
        <CallToActionDecorated
          href="https://trading.bitfinex.com/t/BVIVF0:USTF0"
          target="_blank"
        >
          <div className="inline text-white">↳</div>
          <span className="text-white">BVIV-PERP</span>
        </CallToActionDecorated>
        <CallToActionDecorated
          href="https://trading.bitfinex.com/t/EVIVF0:USTF0"
          target="_blank"
        >
          <div className="inline text-white">↳</div>
          <span className="text-white">EVIV-PERP</span>
        </CallToActionDecorated>
      </div>
    </div>
  </div>
);

export default Bitfinex;
