/* eslint-disable */
// TradingViewWidget.jsx
// TradingViewWidget.jsx
import useWindowDimensions from '@/hooks/useWindowDimensions';
import React, { useEffect, useRef, memo, useLayoutEffect } from 'react';

function setTvChartWidth(screenWidth: number) {
  const tvScript = document.querySelector(
    'iframe[title="symbol overview TradingView widget"]',
  );

  if (!tvScript) return;

  const str = tvScript.style;
  if (screenWidth < 900) {
    tvScript.style.width = (screenWidth * 5) / 6 + 'px';
  } else if (screenWidth < 1024) {
    tvScript.style.width = '800px';
  } else {
    tvScript.style.width = (screenWidth * 3) / 7 + 'px';
  }
}

function TradingViewWidget() {
  const container = useRef();
  const { width } = useWindowDimensions();
  useLayoutEffect(() => {
    setTvChartWidth(width);
  }, [width]);
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'tv-script';
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js';
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
        {
          "symbols": [
            [
              "VOLMEX:BVIV|1M"
            ],
            [
              "VOLMEX:EVIV|1M"
            ]
          ],
          "chartOnly": false,
          "width": 700,
          "height": 400,
          "locale": "en",
          "colorTheme": "light",
          "autosize": false,
          "showVolume": false,
          "showMA": false,
          "hideDateRanges": false,
          "hideMarketStatus": false,
          "hideSymbolLogo": false,
          "scalePosition": "right",
          "scaleMode": "Normal",
          "fontFamily": "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
          "fontSize": "10",
          "noTimeScale": false,
          "valuesTracking": "1",
          "changeMode": "price-and-percent",
          "chartType": "area",
          "maLineColor": "#2962FF",
          "maLineWidth": 1,
          "maLength": 9,
          "lineWidth": 2,
          "lineType": 0,
          "dateRanges": [
            "1m|30",
            "3m|60",
            "12m|1D",
            "60m|1W",
            "all|1M"
          ]
        }`;
    container.current.appendChild(script);
    setTimeout(() => {
      setTvChartWidth(width);
    }, 300);
  }, []);

  return (
    <div className="tradingview-widget-container " ref={container}>
      <div className="tradingview-widget-container__widget"></div>
      {/* <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div> */}
    </div>
  );
}

export default memo(TradingViewWidget);

/*
{
  color: '#2962FF'
}
*/
/* eslint-enable */
